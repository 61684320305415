<template>
<div>
  <v-card max-width="800" class="mx-auto mt-5" flat>

    <Errors v-bind:errors="errors"/>

    <v-card-title> 
      Stundenkonto <span class="pl-1"><i> {{student.firstname}} {{student.lastname}}</i></span>
      <v-spacer/>
      <BalanceLessonAccount v-bind:balance="balance"/>
    </v-card-title>

    <v-card-text>
      <i>{{student.firstname}} {{student.lastname}}</i> hat <b>{{balance}}</b> Stunden auf dem Stundenkonto gutgeschrieben. <br>
      Hier kannst du die Kontoauszüge ansehen und durchsuchen.
      
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        single-line
        hide-details
        class="mt-4 mb-8"
      ></v-text-field>
      <v-data-table
        :mobile-breakpoint="600"
        :headers="headers"
        :items="statements"
        :search="search"
        hide-default-footer
        disable-pagination
        item-key="id"
        locale="de"
        class="my-2 lessonDataTable"
      >
        <template v-slot:[`item.lessons`]="{ item }">
          <v-chip
            :color="getLightColor(item.lessons)"
            light
            class="font-weight-black"
          >
            {{ item.lessons }}
          </v-chip>
        </template>

      </v-data-table>

      Anmerkung: Die Informationen zu den Stunden werden so gespeichert wie sie angelegt werden. 
      Änderungen in den Stunden werden nicht berücksichtigt.

    </v-card-text>

    <v-card-actions v-if="!formIsExpanded && isAdmin">
      <v-container>
          <v-row class="my-2 mt-8">
              <v-btn
                color="primary"
                width="100%"
                depressed
                @click="expandForm()">
                Stunden manuell hinzufügen
              </v-btn>
          </v-row>
        </v-container>
    </v-card-actions>
  </v-card>

<v-card v-if="formIsExpanded && isAdmin" max-width="800" class="mx-auto mt-5" flat>
  <v-card-title>Stunden manuell hinzufügen</v-card-title>

  <v-card-text>
    Das Stundenkonto manuell auszugleichen sollte nur in einem Fall notwendig sein:
    Wenn eine Extra-Rechnung gezahlt wird, um ein negatives Stundenkonto auszugleichen. <br>
    War das Stundenkonto zum Beispiel bei -6, und diese sechs wurden bezahlt, können manuell 6 Stunden hinzugefügt werden. <br>
    Bitte wende dich an die <a href="https://help.alearn.de">ALearn Hilfe</a> falls du öfter manuell ausgleichen musst. <br>
    <br>
    Du kannst positive und negative Zahlen mit bis zu einer Nachkommastelle eingeben.

    <v-form @submit.prevent v-model="valid" class="mt-9">

      <!-- lessons -->
      <v-text-field 
      type="number"
      label="Anzahl Stunden"
      v-model.trim="manualLessons"
      :rules="digitRules"
      />

      <!-- Notizen eg. Auservertragliche Rechnung gezahlt -->
      <v-textarea
      outlined
      auto-grow
      counter="200"
      :rules="counterRule"
      background-color="accent"
      label="Notizen"
      v-model.trim="manualNotes"
      ></v-textarea>

    </v-form>
  </v-card-text>

  <v-card-actions>
    <v-container>
        <v-row>
            <v-btn
              color="primary"
              width="100%"
              depressed
              :disabled="!valid"
              @click="addManualLessons()">
              Stunden manuell hinzufügen
            </v-btn>
        </v-row>
      </v-container>
  </v-card-actions>
  </v-card>
</div>
</template>

<script>
import { mapState } from 'vuex';
import {auth, studentsCollection, lessonAccountsCollection } from '@/firebase';
import {getInsituteSettings} from '@/assets/js/dataUtils.js';
import {formatDate} from '@/assets/js/timeUtils.js';
import Errors from '@/components/Errors.vue';
import BalanceLessonAccount from '@/components/BalanceLessonAccount.vue';

export default {
  name: 'LessonAccount',
  props: ['studentId'],

  components: {
    Errors,
    BalanceLessonAccount,
  },

  data() {
    return {
      errors: [],
      student: {firstname: 'Schüler:in'},
      balance: '',
      formIsExpanded: false,
      manualLessons: '',
      manualNotes: '',
      counterRule: [
        v => !!v || 'Gib eine Grund für den manuellen Ausgleich an.',
        v => (v || '' ).length <= 200 || 'Gib maximal 200 Zeichen ein'
      ],
      digitRules: [
        v => !!v || 'Gib eine Anzahl an Stunden ein an.',
        v => /^-?(?!0*\.?0+$)\d*$/.test(v) || 'Gib eine positive oder negative Zahl ohne Kommastelle an.',
      ],
      valid: false,
      instituteId: '',
      search: '',
      isExpanded: false,
      headers: [
          {
            text: 'Stunden',
            align: 'start',
            value: 'lessons',
          },
          { text: 'Datum', value: 'date' },
          { text: 'Fach', value: 'subject' },
          { text: 'Lehrer', value: 'teacher' },
          { text: 'Notizen', value: 'notes'},
        ],
      statements: [], //lesson account statements
      sumOfLessonsInStatements: 0,
    }
  },

  computed: {
    ...mapState(['isAdmin']),
  },

  async created() {
    //Get the institueId of the currently logged in user
    this.instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;
    this.fetchData();
  },

  methods: {

    async fetchData() {
      await this.getStudent();
      await this.getStatements();
      this.checkBalance();
    },

    async getStudent(){

      try {
        const studentDoc = await studentsCollection.doc(this.studentId).get();
        this.student = studentDoc.data();
        this.student.id = studentDoc.id;
        this.balance = this.student.balanceLessonAccount;

      } catch { 
        this.errors.push({
          text: 'Die Schülerin / der Schüler konnte nicht aus der Datenbank geladen werden.', 
          type:'firestore',
        })
        throw "Could not find this doument in firestore";
      }
    },

    async getStatements(){
      await lessonAccountsCollection
        .where('instituteId', '==', this.instituteId)
        .where('studentId', '==', this.studentId)
        .orderBy('date', "desc")
        .limit(100)
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            const data = doc.data();
            this.statements.push({
              lessons: data.lessons,
              date: formatDate(data.date),
              subject: data.subject,
              teacher: data.teacherName,
              id: doc.id,
              notes: data.notes,
            })
            this.sumOfLessonsInStatements = this.sumOfLessonsInStatements + parseFloat(doc.data().lessons);
          })
          
        }).catch((error) => {
          console.log("Error getting documents: ", error);
          this.errors.push({
            text: 'Die Stundenkonto Aufzeichnungen konnten nicht aus der Datenbank geladen werden.', 
            type:'firestore',
          })
        });
    },

    /**
     * @description Checks if the value "balanceLessonAccount" aus der Studentcollection mit der Summe der Stunden 
     * in den Statements aus lessonAccountCollection übereinstimmt
     */
    checkBalance() {
      if(this.balance != Math.round(this.sumOfLessonsInStatements * 10) / 10){
        this.errors.push({
          text: 'Die Gutschrift und der Kontoauszug des Stundenkontos stimmen nicht überein.', //könnte daran liegen, dass mehr als 100 (limit) statements im stundenkonto sind 
          type: 'help',
        })
      }
    },

    getLightColor (lessons) {
        if (lessons >= 0) return 'var(--v-success-lighten4)'
        else return 'var(--v-error-lighten4)' //error color
    },

    expandForm(){
      this. formIsExpanded = !this.formIsExpanded;
    },

    async addManualLessons() {
      if(this.valid){

        try{
          const minEventDuration = (await getInsituteSettings()).minEventDuration;

          await this.$store.dispatch('editLessonAccount', {
            studentId: this.studentId, 
            minutes: parseInt(this.manualLessons) * parseInt(minEventDuration), 
            notes: '[Manuell] ' + this.manualNotes, 
            date: new Date().toISOString().substr(0,10), //today
            subject: '', 
            teacherName: ''
          })

          this.statements = [];
          this.sumOfLessonsInStatements = 0;
          this.resetForm();
          this.fetchData();
          window.scroll(0,0); //scroll to top to display new lesson acocunt
        } catch {
          this.errors.push({
            text: 'Die Stunde konnte nicht manuell hinzugefügt werden.',
            type: 'firestore',
          })
        }
      }
    },

    resetForm() {
      this.formIsExpanded = false;
      this.manualLessons = '';
      this.manualNotes = '';
    }

  }
}
</script>

<style lang='scss'>

/* remove the shadow of the extended boxes */
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}
/* alternative:
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
} */

/* is a workarround to prevent the table from breaking when expanding the notes and not displaying it again.
see also https://github.com/vuetifyjs/vuetify/issues/11745
also added :mobile-breakpoint="600" to v-data-table to make it expicit. 
alternative inside @media: ::v-deep tbody {display: block;} but then, use <style scoped lang='scss'> but then, box shadow removing does not work
*/
@media only screen and (max-width: 599px) {
  .lessonDataTable > div > table > tbody {
    display: block !important;
  }
}

/* workarround for bugfix: on iOS the cursor is invisible when first clicking into the field, see https://github.com/vuetifyjs/vuetify/issues/13628 */
.v-text-field > .v-input__control > .v-input__slot {
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

</style>
